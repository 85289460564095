/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { useSearchParams } from 'react-router-dom';
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { Page } from './Page';

const firebaseConfigProd = {
  apiKey: 'AIzaSyCAylrZACHOWchl6oAbdKGMGQnIaSRANxk',
  authDomain: 'bitloops.firebaseapp.com',
  databaseURL:
    'https://bitloops-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bitloops',
  storageBucket: 'bitloops.appspot.com',
  messagingSenderId: '64530998614',
  appId: '1:64530998614:web:fca878dffab35fa586a2f7',
  measurementId: 'G-9584QEPJ51',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyBH_i7EJ3XCElusPhkolNiPDP2u1p1or8Q',
  authDomain: 'bitloops-dev.firebaseapp.com',
  databaseURL:
    'https://bitloops-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bitloops-dev',
  storageBucket: 'bitloops-dev.appspot.com',
  messagingSenderId: '1099356987711',
  appId: '1:1099356987711:web:007d5608207da4bd59b38a',
  measurementId: 'G-LWF85JKXSF',
};

const SERVER_URL_PROD = 'https://d2c.bitloops.net';
const SERVER_URL_DEV = 'https://d2c-dev.bitloops.net';

const prodApp = initializeApp(firebaseConfigProd, 'prod');
const devApp = initializeApp(firebaseConfigDev, 'dev');
const authProd = getAuth(prodApp);
const authDev = getAuth(devApp);

function App() {
  const [user, setUser] = React.useState<any>(null);
  const [figmaUserId, setFigmaUserId] = React.useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [nonce, setNonce] = React.useState<string | null>(null);
  const [auth, setAuth] = React.useState<any>(null);
  const [externalButtonPressed, setExternalButtonPressed] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    const nonce = searchParams.get('nonce');
    const figmaUserId = searchParams.get('figmaUserId');
    const google = searchParams.get('google');
    const github = searchParams.get('github');

    if (nonce) {
      setNonce(nonce);
      if (isDevelopment(nonce)) {
        setAuth(authDev);
      } else {
        setAuth(authProd);
      }
    }
    if (figmaUserId) {
      setFigmaUserId(figmaUserId);
    }
    if (google) {
      setExternalButtonPressed('google');
    }
    if (github) {
      setExternalButtonPressed('github');
    }
  }, [searchParams, auth]);

  useEffect(() => {
    if (!nonce && externalButtonPressed) {
      const isDevelopmentEnv = isDevelopment(nonce);
      const SERVER_URL = isDevelopmentEnv ? SERVER_URL_DEV : SERVER_URL_PROD;
      fetch(`${SERVER_URL}/api/auth/v1/initiate-auth-process`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ client: 'bitloops.com' }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          // redirect the user to the home page
          window.location.assign(`/?nonce=${data.nonce}`);
        });
    }
  }, [nonce, externalButtonPressed]);

  useEffect(() => {
    if (!nonce || !user || !auth) return;
    else {
      const isDevelopmentEnv = isDevelopment(nonce);
      const SERVER_URL = isDevelopmentEnv ? SERVER_URL_DEV : SERVER_URL_PROD;
      // console.log('Sending', {nonce, figmaUserId, status: 'success', user});
      const payload: {
        nonce: string;
        status: string;
        user: any;
        figmaUserId?: string;
      } = { nonce, status: 'success', user };
      if (figmaUserId) {
        payload.figmaUserId = figmaUserId;
      }
      fetch(`${SERVER_URL}/api/auth/v1/auth-completed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nonce, status: 'success', user }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          // redirect the user to the home page
          window.location.assign('/');
        });
    }
  }, [nonce, figmaUserId, user, auth]);

  useEffect(() => {
    if (!auth) return;
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          // setUser(result.user);
          // console.log('GitHub login redirect result: ', result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log('setting user', user);
        setUser(user);
        // send a POST request to the server with the user info
      } else {
        setUser(null);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const logout = () => {
    if (!auth) return;

    signOut(auth)
      .then(() => {
        console.log('Logged out');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginWithGoogle = () => {
    if (!auth) return;

    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // console.log(result);
      })
      .catch((error: any) => {
        console.error(error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          const customParameters: any = { login_hint: error.customData.email };
          if (nonce) customParameters.nonce = nonce;
          if (figmaUserId) customParameters.figmaUserId = figmaUserId;
          signInWithRedirect(
            auth,
            new GithubAuthProvider().setCustomParameters(customParameters)
          );
        }
      });
  };

  const loginWithGitHub = () => {
    if (!auth) return;

    signInWithPopup(auth, new GithubAuthProvider())
      .then((result) => {
        console.log(result);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.code === 'auth/account-exists-with-different-credential') {
          const customParameters: any = { login_hint: error.customData.email };
          if (nonce) customParameters.nonce = nonce;
          if (figmaUserId) customParameters.figmaUserId = figmaUserId;
          signInWithRedirect(
            auth,
            new GoogleAuthProvider().setCustomParameters(customParameters)
          );
        }
      });
  };

  return (
    <Page
      user={user}
      nonce={nonce}
      loginWithGoogle={loginWithGoogle}
      loginWithGitHub={loginWithGitHub}
      logout={logout}
    />
  );
}

// Id nonce starts with 'dev' then it's development
const isDevelopment = (nonce: string | null): boolean => {
  if (!nonce) return false;
  return nonce.startsWith('dev');
};

export default App;
