interface StepProps {
    title: string;
    image?: string;
    children: React.ReactNode;
}


export function Step(props: StepProps) {
    const { title, image, children } = props;
    return (
        <li className="flex flex-col gap-3 text-center items-center min-w-[483px]">
            <h3 className="text-bitloops font-bold text-[1.7rem]">{title}</h3>
            <p className="max-w-[60%]">{children}</p>
            {image && <img className="h-auto max-w-sixty350" src={image} alt={title} />}
        </li>
    );
}