import Bitloops from './assets/Bitloops-Full-White-Background.svg';
import Google from './assets/google/google-white.svg';
import GitHub from './assets/github/github-mark-white.svg';
import Logout from './assets/logout.svg';
import { Button } from './Button';
import { Step } from './Step';

interface PageProps {
    user: any;
    nonce: string | null;
    loginWithGoogle: () => void;
    loginWithGitHub: () => void;
    logout: () => void;
}

export const Page = (props: PageProps) => {
  const { user, nonce, loginWithGoogle, loginWithGitHub, logout } = props;  
  return (
    <main className="mt-8">
      <div className="flex flex-col items-center gap-10 mb-10">
        <div className="bg-white max-w-[1200px] w-[80%] p-8 shadow-2xl rounded-lg min-h-[600px]">
        <div className="flex flex-col items-center mb-10 mt-4">
          <img
            className="max-w-[300px] w-[80%]"
            src={Bitloops}
            alt="Bitloops"
          />
        </div>
        {!user && nonce && (
          <div className="ctas flex flex-wrap items-center gap-6 justify-center mt-14">
              <Button
                onClick={loginWithGitHub}
                icon={GitHub}
                text={'Connect with GitHub'}
                tabIndex={0}
                className={'github'}
              />
            <Button
                onClick={loginWithGoogle}
                icon={Google}
                text={'Connect with Google'}
                tabIndex={1}
                className={'google'}
            />
          </div>
        )}
{user && (
  <div className="flex flex-col gap-6 items-center">
    <h1 className="text-black text-center font-bold text-[2rem]">
      You have successfully logged in! 
    </h1>
    <div className="checkmark">
      <div className="checkmark_circle"></div>
      <div className="checkmark_stem"></div>
      <div className="checkmark_kick"></div>
    </div>
    <h2 className="text-black text-center font-bold text-[1.5rem]">To start converting designs into code, follow these steps:
    </h2>
    <div className="mx-auto my-6" style={{ maxWidth: '100%', padding: '0 1rem' }}>
      <ul className="text-black text-lg grid gap-8 text-center align-items-start" 
        style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(483px, 1fr))',
            justifyContent: 'center',
        }}>
        <Step title={'1. Open Your Design'} image={'/assets/images/figma-logo.jpg'}>
            Launch Figma and open the design file you want to convert
        </Step>
        <Step title={'2. Invite Artie'} image={'/assets/images/invite.png'}>
            Share your design with Artie, a dedicated Frontend AI assistant, by adding ai@bitloops.com as a viewer.
        </Step>
        <Step title={'3. Tag Artie'} image={'/assets/images/tag-artie.png'}>
            Add a comment anywhere on the design tagging Artie and typing: 
            <span className="italic">“Hey @Artie, convert this design into code, please.</span>
        </Step>
        <Step title={'4. Retrieve your code'} image={'/assets/images/vs-code.png'}>
            Artie will process your request and provide instructions on how you can access and integrate the generated code into your VS Code project seamlessly.
        </Step>
    </ul>
      <p className="mt-3"></p>
    </div>
    
    <p className="text-black text-center text-[1.5rem]">
        It's that simple! Save days of work with Bitloops! 
    </p>
    <p className="text-black text-center text-[1.2rem]">
        You can close this window at any time.
    </p>
    <button
      className="fixed top-0 right-0 flex flex-row gap-4 items-center text-center text-white px-3 py-2 mt-8"
      onClick={logout}
    >
      <img className="w-7 h-7 text-white" src={Logout} alt={'Logout'} />
    </button>
  </div>
)}
</div>
      </div>
    </main>
  );
}